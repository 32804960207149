import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const show = definePartsStyle({
  track: {
    w: '27px',
    h: '13px',
    p: '0px',
    bgColor: 'transparent',
    border: '1px solid',
    borderColor: 'grey',
    _checked: { border: '1px solid', borderColor: 'mainBlue' },
    _focusVisible: { boxShadow: 'none', outline: 'none' },
  },
  thumb: {
    w: '13px',
    h: '13px',
    bgColor: 'grey',
    _checked: { bgColor: 'mainBlue' },
  },
});

export const customSwitchStyles = defineMultiStyleConfig({
  variants: { show },
});
