import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderWidth: "0.75px",
    borderRadius: "50%",
    borderColor: "mainBlue",
    position: "relative",
    _checked: {
      bg: "white",
      _before: {
        position: "absolute",
        bg: "mainBlue",
        w: "9px",
        h: "9px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    },
  },
  label: {
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.35",
    fontStyle: "normal",
    letterSpacing: "0.28px",
  },
  container: {
    w: "100%",
    color: "mainBlue",
  },
});

export const customRadioTheme = defineMultiStyleConfig({
  baseStyle,
});
