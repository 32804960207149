import { extendTheme } from "@chakra-ui/react";
import { customRadioTheme } from "./customTheme/customRadioTheme";
import { customCheckboxTheme } from "./customTheme/customCheckboxTheme";
import { customButtonStyles } from "./customTheme/customButtonStyles";
import { customSwitchStyles } from "./customTheme/customSwitchStyles";

const theme = extendTheme({
  styles: {
    global: { body: { minHeight: "100vh", backgroundColor: "#F0F2F5" } },
  },

  colors: {
    naturalBlack: "#000000",
    mainBlue: "#0A2540",
    blue: "#134E88",
    lightBlue: "#205F9D",
    orangeHighlight: "#F9B25B",
    mainOrange: "#E3AA66",
    grey: "#7E869E",
    menuGray: "#F0F2F5",
    borderGray: "#CCD2E3",
    menuSearchColor: "#F0F2F5",
    dashboardBackground: "#FBFBFB",
    white: "#FFFFFF",
    red: "#D4470F",
    errorRed: "#EB5757",
    greenNew: "#082C34",
    black: "#22262A",
    darkRedNew: "#BD3826",
    green: "#29AE61",
    disabledBg: "#BDBDBD",
    disabledColor: "#E0E0E0",
    skeletonBg: "#E6E6E6",
    footerSection: "#777",
    venture: "#9C9C9C",
    lightGray: "#E1E1E1",
    lightBlue1: "#CFE5FA",
    lightBlue2: "#EFF5FB",
    lightYellow: "#FAF3CF",
    lightYellow2: "#F4FBEF",
    lightPurple: "#F4CFFA",
    lightGreen: "#CFFADB",
    lightGreen2: "#F4FBEF",
  },

  breakpoints: {
    base: "320px",
    base1: "400px",
    base2: "440px",
    sm: "480px",
    sml: "530px",
    smd: "595px",
    forCard2: "598px",
    smx: "640px",
    md: "768px",
    md2: "860px",
    forCard3: "915px",
    mdx: "920px",
    lg: "992px",
    lg1: "1059px",
    lg2: "1140px",
    lg3: "1170px",
    lgx: "1200px",
    xl: "1280px",
    xl1: "1300px",
    xl2: "1340px",
    desk: "1440px",
  },
  components: {
    Radio: customRadioTheme,
    Checkbox: customCheckboxTheme,
    Button: customButtonStyles,
    Switch: customSwitchStyles,
  },
});

export default theme;
