import axios from "axios";
import { ENVIRONMENT_CONFIGS } from "../../../env_config";

const currentEnv = process.env.NEXT_ENVIRONMENT || "development";
const baseURL = ENVIRONMENT_CONFIGS.strapi.url
  ? `${ENVIRONMENT_CONFIGS.strapi.url}/api`
  : undefined;

console.info(`Configured baseURL for Strapi: ${baseURL || "Not set"}`);

if (!baseURL) {
  console.warn(
    "Strapi baseURL is undefined. Please check your environment configuration."
  );
}

export const axiosServiceStrapi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "User-Agent":
      "Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:106.0) Gecko/20100101 Firefox/106.0",
  },
});

axiosServiceStrapi.interceptors.request.use(
  (config) => {
    const token = process.env.NEXT_PUBLIC_STRAPI_API_TOKEN;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      console.error("Strapi API token is missing");
    }
    console.debug("Sending request to Strapi:", { url: config.url });
    return config;
  },
  (error) => {
    console.error("Error in request interceptor:", error);
    return Promise.reject(error);
  }
);

axiosServiceStrapi.interceptors.response.use(
  (response) => {
    console.debug("Successful Strapi response:", { url: response.config.url });
    return response;
  },
  (error) => {
    const errorInfo = {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      message: error.message,
    };
    console.error("Strapi Request Failed:", errorInfo);

    return Promise.reject(error, { message: "Strapi request failed" });
  }
);
