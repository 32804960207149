import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENVIRONMENT_CONFIGS } from "env_config";
import qs from "qs";
import { axiosServiceStrapi } from "./apiService/axiosServiceStrapi";

const articlesPopulate = {
  0: "categories",
  1: "author",
  2: "author.image",
  3: "seo.metaImage",
};

export const strapiApi = createApi({
  reducerPath: "strapi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENVIRONMENT_CONFIGS.strapi.url}/api`,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        `Bearer ${ENVIRONMENT_CONFIGS.strapi.apiToken}`
      );
    },
  }),
  endpoints: (builder) => ({
    getPrices: builder.query({
      query: () => ({
        method: "GET",
        url: "/price",
        params: {
          populate: ["priceData.advantages"],
        },
      }),
      transformResponse: (response, meta) => response?.data,
    }),
    getMembers: builder.query({
      query: () => ({
        method: "GET",
        url: "/member",
        params: {
          populate: ["images"],
        },
      }),
      transformResponse: (response, meta) => response?.data,
    }),
    getArticles: builder.query({
      async queryFn({ id, filters, pagination }) {
        try {
          const query = {
            populate: articlesPopulate,
            filters,
            pagination,
            sort: ["createdDate:desc"],
          };
          const queryStr = qs.stringify(query, { encodeValuesOnly: true });
          const { data } = await axiosServiceStrapi.get(
            `articles/${id || ""}?${queryStr}`
          );

          return { data };
        } catch (error) {}
      },

      //   query: ({ id, filters, pagination }) => {
      //     const query = {
      //       populate: articlesPopulate,
      //       filters,
      //       pagination,
      //       sort: ["createdDate:desc"],
      //     };
      //     const queryStr = qs.stringify(query, { encodeValuesOnly: true });
      //     return {
      //       method: "GET",
      //       url: `articles/${id || ""}?${queryStr}`,
      //     };
      //   },
    }),
    getOneArticle: builder.query({
      query: (slug) => {
        const queryStr = qs.stringify(
          { populate: articlesPopulate },
          { encodeValuesOnly: true }
        );
        return {
          method: "GET",
          url: `/slugify/slugs/article/${slug}?${queryStr}`,
        };
      },
    }),
    getUsers: builder.query({
      query: () => ({
        method: "GET",
        url: "/authors",
        params: {
          populate: "*",
        },
      }),
      transformResponse: (response, meta) => response?.data,
    }),
    getAuthor: builder.query({
      query: (slug) => ({
        method: "GET",
        url: `/slugify/slugs/author/${slug}`,
        params: {
          populate: [
            "*",
            "image",
            "articles.categories",
            "articles.author",
            "articles.author.image",
            "articles.seo.metaImage",
          ],
        },
      }),
      transformResponse: (response, meta) => response?.data,
    }),
    getCategories: builder.query({
      query: () => ({
        method: "GET",
        url: "/categories",
        params: {
          populate: "*",
        },
      }),
      transformResponse: (response, meta) => response?.data,
    }),
    getCategory: builder.query({
      query: (slug) => ({
        method: "GET",
        url: `/slugify/slugs/category/${slug}`,
        params: {
          populate: [
            "*",
            "articles.categories",
            "articles.author",
            "articles.author.image",
            "articles.seo.metaImage",
          ],
        },
      }),
      transformResponse: (response, meta) => response?.data,
    }),
    getStaticPage: builder.query({
      query: (slug) => {
        return {
          method: "GET",
          url: `/slugify/slugs/static-page/${slug}`,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetArticlesQuery,
  useGetArticlesQuery,
  useGetPricesQuery,
  useGetMembersQuery,
  useGetOneArticleQuery,
  useGetUsersQuery,
  useGetAuthorQuery,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useGetStaticPageQuery,
} = strapiApi;
