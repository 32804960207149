import {defineStyleConfig} from "@chakra-ui/react";

const defaultStyles = {
    w: "100%",
    maxW: "200px",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "110%",
    letterSpacing: "0.28px",
}

const customButtonStyles = defineStyleConfig({
    variants: {
        primary: {
            ...defaultStyles,
            bg: "#0A2540",
            border: "1px solid #0A2540",
            color: "#fff",

            _hover: {
                bg: "transparent",
                color: "#7E869E",
            }
        },
        primaryRed: {
            ...defaultStyles,
            bg: "#ff0033",
            color: "#fff",

            _hover: {
                bg: "#cc0022",
            }
        },
        secondary: {
            ...defaultStyles,
            bg: "transparent",
            border: "1px solid #0A2540",

            color: "#7E869E",

            _hover: {
                bg: "#0A2540",
                color: "#fff",
            }
        }
    }
})

export {customButtonStyles}